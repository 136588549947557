<template>
  <div class="bt-home pb-5 mb-5">
    <ChartBulanan />
    <ChartCabang />
    <ChartPetugas />
  </div>
</template>
<script>
import BaseChart from "@/components/BaseChart.vue";
import ChartCabang from "@/components/Chart/ChartCabang.vue";
import ChartBulanan from "@/components/Chart/ChartBulanan.vue";
import ChartPetugas from "@/components/Chart/ChartPetugas.vue";
export default {
  name: "PenagihanDashboard",
  components: {
    BaseChart,
    ChartCabang,
    ChartBulanan,
    ChartPetugas,
  },
};
</script>
